// import * as crypto from 'crypto';
import uniq from 'lodash/uniq';
import moment from 'moment';

export interface DeepScanDTO {
  id: string;
  isLightScan: boolean;
  enableCharts: boolean;
  installedAgents: boolean;
  scannedBrowserPasswords: boolean;
  configuredPhishing: boolean;
  uploadedEmployeeList: boolean;
  completedSaasAlerts: boolean;
  employeeListPath: string | null;
  employeeListFileName: string | null;
  nijaOneLink: string | null;
  addigyLink: string | null;
  actifileID: string | null;
  actifileUninstallKey: string | null;
  nodewareId: string | null;
  augumentId: string | null;
  piiToolsHost: string | null;
  saasAlertLink: string | null;
  activeAssetsCount: number;
  m365Connected: boolean;
  gwsConnected: boolean;
  piiConfig: PiiConfig;
}

export interface DeepScanEventStat {
  eventType: string;
  total: number;
}

export interface AgentVersions {
  windows: string;
  macs: string;
}

export interface DeepScanTargetDetailsDTO {
  logs: string;
  error?: string;
}

export interface DeepScanEventDTO {
  reason?: string;
}

export interface DeepScanDetailsDTO {
  local_user: string | null;
}

export interface TelivyAgentDetailsDTO {
  get_install_keys_at: Date | null;
  version: string | null;
  run_as_admin: string | null;
  stdout: string | null;

  service_log?: string | null;

  nodeware_installed: string | null;
  nodeware_error: string | null;
  nodeware_logs: string | null;
  nodeware_uninstalled: string | null;

  actifile_installed: string | null;
  actifile_error: string | null;
  actifile_logs: string | null;
  actifile_uninstalled: string | null;

  pii_tools_installed: string | null;
  pii_tools_error: string | null;
  pii_tools_logs: string | null;
  pii_tools_uninstalled: string | null;

  augment_installed: string | null;
  augment_error: string | null;
  augment_logs: string | null;
  augment_uninstalled: string | null;

  scan_apps_os_version?: string | null;
  scan_applications_total_found: number | null;
  scan_applications_error?: string | null;
  scan_applications_log?: string | null;
  scan_applications_date?: string | null;

  telivy_installed: string | null;
  telivy_error: string | null;
  telivy_logs: string | null;

  schedule_scan_install_done: string | null;
  schedule_scan_uninstalled: string | null;

  other_error: string | null;
  scan_pii_dryrun_files?: number;
  scan_pii_total_found?: number;
  scan_pii_log?: string;
  scan_pii_error?: string;

  scan_vulnerabilities_error?: string | null;
  scan_network_started?: string | null;

  uninstall_all_log?: string | null;
  uninstall_all_done?: string | null;
  uninstall_all_started?: string | null;
  uninstall_scheduled?: string | null;

  rescan_scheduled?: string | null;
  rescan_started?: string | null;
}

export interface BrowserPasswordDetailsDTO {
  get_install_keys_at: Date | null;
  version: string | null;
  run_as_admin: string | null;

  installed_browsers: string[] | null;
  total_passwords_found: number | null;
  execution_logs: string | null;
  execution_error: string | null;

  user_scanned_at?: string | null;

  scan_pii_log?: string;
  scan_pii_error?: string;
  scan_pii_total_found?: number;

  scan_applications_log?: string | null;
  scan_applications_error?: string | null;
  scan_applications_total_found?: number;

  scan_apps_os_version?: string | null;
}

export interface ControllerLog {
  timestamp: Date;
  status: string;
  value: string;
}

export enum AssetStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  INSTALLED = 'installed',
  ONLINE = 'ONLINE',
  OFFLINE = 'offline',
  UNINSTALLED = 'uninstalled',
}

export interface DeepScanTargetLinkedUserDTO {
  id: string;
  mail: string;
  displayName: string;
}

export interface DeepScanTargetDTO {
  id: string;
  macAddress: string;
  osVersion: string | null;
  hostname: string | null;
  details: DeepScanDetailsDTO | null;
  telivyAgentInstalledAt: string | null;
  telivyAgentDetails: TelivyAgentDetailsDTO | null;
  telivyAgentUninstalledAt: string | null;
  browserPasswordExecutedAt: string | null;
  browserPasswordExecutionDetails: Record<string, BrowserPasswordDetailsDTO> | null;
  vulnerabilityManagementInstalledAt: string | null;
  vulnerabilityManagementDetails: DeepScanTargetDetailsDTO | null;
  assetStatus: AssetStatus;
  remoteCommands: string[];
  controllerLogs: ControllerLog[];
  lastHeartBeatAt: Date | null;
  deepScanId: string;
  piiToolsScanId: string | null;
  piiToolsSummary: PiiScanSummary | null;
  piiToolsScanStatus: string | null;
  piiToolsScanStatusMessage: string | null;
  nmapScanStats: NmapScanStats | null;
  piiConfig: PiiConfig;
  linkedUsers: DeepScanTargetLinkedUserDTO[] | null;
}

export interface DeepScanMacAddressDataDTO {
  id: string;
  macPrefix: string;
  vendorName: string;
  blockType: string;
  private: boolean;
  lastUpdate: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface DeviceScanData {
  connectedPorts: Array<{
    port: number;
    potentialServices: string;
  }>;
  listeningPorts?: Array<{
    port: number;
    processName: string;
  }>;
}

export interface DeepScanNetworkHostDTO {
  id: string;
  macAddress: string;
  osVersion: string | null;
  hostName: string | null;
  ipAddress: string | null;
  deepScanId: string;
  macAddressData: DeepScanMacAddressDataDTO | null;
  deviceScanData: DeviceScanData | null;
  gatewayMacAddress: string | null;
}

export interface DeepScanNetworkHostNmapOutputDTO {
  id: string;
  os: string[];
  services: Array<{
    port: number;
    service: string;
  }>;
}

export interface ParsedPiiTarget
  extends Pick<DeepScanTargetDTO, 'piiToolsScanStatus' | 'piiToolsScanStatusMessage' | 'piiToolsScanId'> {
  riskValuation: number;
  files: number;
  sensitive: number;
  hostname: string;
}

export interface DeepScanTargetPiiSummaryDTO {
  hostname: string;
  error: string | null;
  started: string | null;
  files: number;
  sensitiveFiles: number;
  totalRisk: number;
}

export interface DeepScanM365AuthenticationMethod {
  '@odata.type': string;
  id: string;
  password?: string | null;
  createdDateTime: string;
}

export interface DeepScanM365MailboxSettings {
  language: {
    locale: string;
    displayName: string;
  };
  timeZone: string;
  dateFormat: string;
  timeFormat: string;
  userPurpose: string;
  workingHours: {
    endTime: string;
    timeZone: {
      name: string;
    };
    startTime: string;
    daysOfWeek: string[];
  };
  archiveFolder: string;
  '@odata.context': string;
  automaticRepliesSetting: {
    status: string;
    externalAudience: string;
    externalReplyMessage: string;
    internalReplyMessage: string;
    scheduledEndDateTime: {
      dateTime: string;
      timeZone: string;
    };
    scheduledStartDateTime: {
      dateTime: string;
      timeZone: string;
    };
  };
  delegateMeetingMessageDeliveryOptions: string;
}

export interface DeepScanM365SubscribedSku {
  id: string;
  skuId: string;
  accountId: string;
  appliesTo: string;
  accountName: string;
  prepaidUnits: {
    enabled: number;
    warning: number;
    lockedOut: number;
    suspended: number;
  };
  servicePlans: Array<{
    appliesTo: string;
    servicePlanId: string;
    servicePlanName: string;
    provisioningStatus: string;
  }>;
  consumedUnits: number;
  skuPartNumber: string;
  subscriptionIds: Array<string>;
  capabilityStatus: string;
}

export interface DeepScanM365MemberRole {
  id: string;
  '@odata.type': string;
  description: string;
  displayName: string;
  roleTemplateId: string;
  deletedDateTime: string;
}

export interface DeepScanM365User {
  id: string;
  mail: string;
  surname: string | null;
  jobTitle: string | null;
  givenName: string;
  displayName: string;
  mobilePhone: string | null;
  businessPhones: string[];
  officeLocation: string | null;
  preferredLanguage: string | null;
  userPrincipalName: string;
  authenticationMethods?: DeepScanM365AuthenticationMethod[];
  mailboxSettings?: DeepScanM365MailboxSettings;
  signInActivity?: {
    lastSignInDateTime: string;
    lastSignInRequestId: string;
    lastNonInteractiveSignInDateTime: string;
    lastNonInteractiveSignInRequestId: string;
  };
  accountEnabled?: boolean;
  subscribedSkus?: {
    value: DeepScanM365SubscribedSku[];
  };
  memberOf?: {
    value: Array<DeepScanM365MemberRole>;
  };
  createdDateTime: string;
}

export interface DeepScanM365ControlScore {
  count: string;
  score: number;
  source: string;
  lastSynced: string;
  controlName: string;
  description: string;
  IsApplicable: string;
  controlCategory: string;
  scoreInPercentage: number;
  implementationStatus: string;
  title?: string;
  maxScore?: number;
  scoreImpact?: number;
}

export interface DeepScanM365ComparativeScore {
  basis: string;
  appsScore: number;
  dataScore: number;
  deviceScore: number;
  appsScoreMax: number;
  averageScore: number;
  dataScoreMax: number;
  identityScore: number;
  deviceScoreMax: number;
  identityScoreMax: number;
  infrastructureScore: number;
  infrastructureScoreMax: number;
}

export interface DeepScanM365SecurityScore {
  id: string;
  maxScore: number;
  currentScore: number;
  azureTenantId: string;
  controlScores: DeepScanM365ControlScore[];
  activeUserCount: number;
  createdDateTime: string;
  enabledServices: string[];
  licensedUserCount: number;
  vendorInformation: {
    vendor: string;
    provider: string;
    subProvider: string | null;
    providerVersion: string | null;
  };
  averageComparativeScores: DeepScanM365ComparativeScore[];
}

export interface DeepScanM365ControlProfile {
  id: string;
  rank: number;
  tier: string;
  title: string;
  service: string;
  threats: string[];
  maxScore: number;
  actionUrl: string;
  actionType: string;
  deprecated: boolean;
  userImpact: string;
  remediation: string;
  azureTenantId: string;
  controlCategory: string;
  remediationImpact: string;
  vendorInformation: {
    vendor: string;
    provider: string;
    subProvider: string | null;
    providerVersion: string | null;
  };
  implementationCost: string;
  controlStateUpdates: Array<{
    state: string;
    comment: string | null;
    updatedBy: string | null;
    assignedTo: string | null;
    updatedDateTime: string | null;
  }>;
  lastModifiedDateTime: string | null;
  complianceInformation: any[];
}

export interface DeepScanM365AuditLogSignIn {
  id: string;
  createdDateTime: string;
  userDisplayName: string;
  userPrincipalName: string;
  userId: string;
  appId: string;
  appDisplayName: string;
  ipAddress: string;
  clientAppUsed: string;
  correlationId: string;
  conditionalAccessStatus: string;
  isInteractive: boolean;
  riskDetail: string;
  riskLevelAggregated: string;
  riskLevelDuringSignIn: string;
  riskState: string;
  riskEventTypes: any[];
  riskEventTypes_v2: any[];
  resourceDisplayName: string;
  resourceId: string;
  status: {
    errorCode: number;
    failureReason: string;
    additionalDetails: string;
  };
  deviceDetail: {
    deviceId: string;
    displayName: string;
    operatingSystem: string;
    browser: string;
    isCompliant: boolean;
    isManaged: boolean;
    trustType: string;
  };
  location: {
    city: string;
    state: string;
    countryOrRegion: string;
    geoCoordinates: {
      altitude: number | null;
      latitude: number;
      longitude: number;
    };
  };
  appliedConditionalAccessPolicies: any[];
}

export interface DeepScanM365Users {
  value: DeepScanM365User[];
}

export interface DeepScanM365SecurityScores {
  value: DeepScanM365SecurityScore[];
}

export interface DeepScanM365SecurityScoreControlProfiles {
  value: DeepScanM365ControlProfile[];
}

export interface DeepScanM365UserMfaReports {
  error?: string;
  value: any[];
}

export interface DeepScanM365AuditLogSignIns {
  error?: string;
  value: DeepScanM365AuditLogSignIn[];
}

export interface DeepScanM365SecurityData {
  users: DeepScanM365Users;
  securityScore: DeepScanM365SecurityScores;
  securityScoreControlProfiles: DeepScanM365SecurityScoreControlProfiles;
  userMfaReport: DeepScanM365UserMfaReports;
  auditLogsSignIns: DeepScanM365AuditLogSignIns;
}

export type DeepScanM365SecurityDataValue =
  | DeepScanM365Users
  | DeepScanM365SecurityScores
  | DeepScanM365SecurityScoreControlProfiles
  | DeepScanM365UserMfaReports
  | DeepScanM365AuditLogSignIns;

export interface DeepScanGwsUser {
  kind: string;
  id: string;
  etag: string;
  primaryEmail: string;
  name: {
    givenName: string;
    familyName: string;
    fullName: string;
  };
  isAdmin: boolean;
  isDelegatedAdmin: boolean;
  lastLoginTime: Date;
  creationTime: Date;
  agreedToTerms: boolean;
  suspended: boolean;
  archived: boolean;
  changePasswordAtNextLogin: boolean;
  ipWhitelisted: boolean;
  emails: Array<{
    address: string;
    type?: string;
    primary?: boolean;
  }>;
  languages: Array<{
    languageCode: string;
    preference: string;
  }>;
  nonEditableAliases: Array<string>;
  customerId: string;
  orgUnitPath: string;
  isMailboxSetup: boolean;
  isEnrolledIn2Sv: boolean;
  isEnforcedIn2Sv: boolean;
  includeInGlobalAddressList: boolean;
}

export interface DeepScanGwsDomainUsers {
  kind: string;
  etag: string;
  users: Array<DeepScanGwsUser>;
  error?: string;
}

export interface DeepScanGwsActivityLogin {
  id: {
    time: Date;
    customerId: string;
    applicationName: string;
    uniqueQualifier: string;
  };
  etag: string;
  kind: string;
  actor: {
    email: string;
    profileId: string;
  };
  events: Array<{
    name: string;
    type: string;
    parameters: Array<{
      name: string;
      value?: string;
      multivalue?: string[];
      boolValue?: boolean;
    }>;
  }>;
  ipAddress: string;
  location?: {
    city: string;
    state: string;
    countryOrRegion: string;
    geoCoordinates: {
      altitude: number | null;
      latitude: number;
      longitude: number;
    };
  };
}

export type DeepScanGwsSecurityDataValue = DeepScanGwsUser | DeepScanGwsDomainUsers | DeepScanGwsActivityLogin[] | Date;

export interface PiiScanSummary {
  risk: number;
  filesScanned: number;
  sensitiveFiles: number;
}

export interface PiiTypes {
  // surname: number;
  phone: number;
  date_of_birth: number;
  // postal_code: number;
  // location: number;
  // street: number;
  ssn: number;
  email: number;
  credit_card: number;
  ip: number;
  oauth_token: number;
  routing_number: number;
  // swift_code: number;
  iban_code: number;
  medical_record: number;
  passport: number;
}

export type PiiCountry = 'US' | 'CA' | 'UK' | 'ZA' | 'AU' | 'NZ';

export type PiiCountryNames = { [key in PiiCountry]: string };
export type PiiCurrencySymbols = { [key in PiiCountry]: string };
export type PiiCurrencyConversions = { [key in PiiCountry]: number };

export const PiiCountries: PiiCountryNames = {
  US: 'United States',
  CA: 'Canada',
  UK: 'United Kingdom',
  ZA: 'South Africa',
  AU: 'Australia',
  NZ: 'New Zealand',
};

export const PiiCurrency: PiiCurrencySymbols = {
  US: '$',
  CA: 'C$ ',
  UK: '£',
  ZA: 'R',
  AU: 'A$ ',
  NZ: 'NZ$ ',
};

export const PiiCurrencyConversion: PiiCurrencyConversions = {
  US: 1,
  CA: 1.35,
  UK: 0.77,
  ZA: 17.9,
  AU: 1.5,
  NZ: 1.63,
};

export interface PiiConfig {
  country: PiiCountry;
  maskPii: boolean;
  currency: string;
  costs: PiiTypes;
}

export interface NmapScanStats {
  cveCounts: {
    info: number;
    na: number;
    low: number;
    medium: number;
    high: number;
  };
  epssMax: number;
}

export interface NodewareTotals {
  total: number;
  critical: number;
  high: number;
  medium: number;
  low: number;
  info: number;
}

export interface NodewareAsset {
  name: string;
  uuid: string;
  isExternal: boolean;
  isAgent: boolean;
  lastSeen?: string;
  firstSeen?: string;
  ipAddress?: string;
  os?: string;
  macAddress?: string;
  hostname: string;
  score?: number;
  totals?: NodewareTotals;
  ['totals.critical']?: number;
  ['totals.high']?: number;
  ['totals.medium']?: number;
  ['totals.low']?: number;
  ['totals.info']?: number;
}

export interface UpdateDeepScanDTO {
  installedAgents?: boolean;
  configuredPhishing?: boolean;
  uploadedEmployeeList?: boolean;
  completedSaasAlerts?: boolean;
  piiConfig?: PiiConfig;
  nodewareId?: string | null;
}

export interface UploadEmployeeListDTO {
  fileName: string;
}

export interface DataRiskDistributionDTO {
  piiType: keyof PiiTypes;
  hostname: string;
  matchesCount: number;
  totalRisk: number;
  sensitiveFiles: number;
}

export interface BrowserPasswordDTO {
  id: string;
  hostname: string;
  localUser: string;
  browser: string;
  url: string;
  username: string;
  passwordMasked: string;
  passwordDigest: string | null;
  passwordBreached: number | null;
  passwordStrength: number | null;
  passwordEncrypted: string | null;
  deepScan?: DeepScanDTO | null;
  deepScanId: string;
  deepScanTarget?: DeepScanTargetDTO | null;
  deepScanTargetId: string | null;
}

export interface BrowserPasswordStatsDTO {
  email?: string;
  userName?: string;
  passwordMasked: string;
  passwordDigest: string | null;
  passwordBreached: number | null;
  passwordStrength: number | null;
  hostnames: number;
  localusers: number;
  browsers: number;
  urls: number;
  total: number;
}

export interface ApplicationsRiskReport {
  url?: string;
  company?: string;
  application: string;
  category: string;
  approvalStatus: string;
  risk: string;
  productivity: string;
  users: number;
  visits: number;
  daysActive?: number;
  percentDaysVisited?: number;

  userName?: string; // Only for user view
  deviceName?: string; // Only for user view
  email?: string; // Only for user view
}

export interface OverallSummary {
  deviceRisk: number | null;
  dataRiskParkedLiability: number | null;
  applicationsRisk: number | null;
  activityLog: number | null;
  totalRisk: number | null;
  riskReduction: number | null;
  residualRisk: number | null;
  encryptedPercent: number | null;
  riskPercent: number | null;
  onlinePercent: number | null;
  offlinePercent: number | null;
  notUpdatedPercent: number | null;
}

interface DataRiskSensitive {
  classification: string;
  files: number;
  records: number;
  riskInUsd: number;
  countries?: string;
  regulation: string;
}

interface Process {
  processName: string;
  devices: number;
  filesIn: number;
  filesInRiskInUsd: number;
  filesOur: number;
  filesOutRiskInUsd: number;
  totalRiskInUsd: number;
  sensitive: number;
  lastAction: string;
}

export const ENCRYPTION_METHODS = [
  'None',
  'Advanced Encryption Standard (AES) algorithm enhanced with a diffuser layer, using an AES key size of 128 bits.',
  'Advanced Encryption Standard (AES) algorithm enhanced with a diffuser layer, using an AES key size of 256 bits.',
  'Advanced Encryption Standard (AES) algorithm, using an AES key size of 128 bits.',
  'Advanced Encryption Standard (AES) algorithm, using an AES key size of 256 bits.',
  'Hardware Encryption',
  'XTS using the Advanced Encryption Standard (AES), and an AES key size of 128 bits.',
  'XTS using the Advanced Encryption Standard (AES), and an AES key size of 256 bits',
];

export interface EncryptionStatus {
  CapacityGB: number;
  LockStatus: number;
  MountPoint: string;
  VolumeType: 1;
  ComputerName: string;
  KeyProtector: Array<any>;
  VolumeStatus: number;
  WipePercentage: number;
  MetadataVersion: number;
  EncryptionMethod: number;
  ProtectionStatus: number;
  AutoUnlockEnabled: number | null;
  AutoUnlockKeyStored: number | null;
  EncryptionPercentage: number;
}

export interface Device {
  deviceName: string;
  userName?: string;
  online?: string;
  state?: string;
  riskValuation: number;
  risk: number;
  files?: number | null;
  dryRunTotal?: number | null;
  sensitive: number;
  sharedServices?: string;
  outbound?: number;
  source: 'actifile' | 'telivy';
  error?: string | null;
  started?: string | null;
  completed?: number | null;
  encryptionStatus?: EncryptionStatus[] | null;
}

interface Classification {
  filterName: string;
  type: string;
  encryption: string;
  caption: string;
  processedDevices: number;
}

interface ParsedRiskReport {
  overallSummary: OverallSummary;
  dataRiskSensitive: DataRiskSensitive[];
  applicationRiskWeb: Process[];
  applicationRiskLocal: Process[];
  devices: Device[];
  classification: Classification[];
}

interface FileDetails {
  deviceName: string;
  path: string;
  fileName: string;
  encryptionStatus?: string;
  lastModified: string;
  lastModifiedInDays: number;
  lastUploaded: string;
  creditCardMasterCardNewRecords: number;
  creditCardMasterCardNewLiability: number;
  creditCardVisaNewRecords: number;
  creditCardVisaNewLiability: number;
  ePHIEyeglassPrescriptionRecords: number;
  ePHIEyeglassPrescriptionLiability: number;
  ePHISSNICD10NewRecords: number;
  ePHISSNICD10NewLiability: number;
  piiDateOfBirthUSAMMDDYYRecords: number;
  piiDateOfBirthUSAMMDDYYLiability: number;
  piiEmailListRecords: number;
  piiEmailListLiability: number;
  usACHClearingNumbersRecords: number;
  usACHClearingNumbersLiability: number;
  usDriversLicenseRecords: number;
  usDriversLicenseLiability: number;
  usElectronicFundsTransferAndWireInformationRecords: number;
  usElectronicFundsTransferAndWireInformationLiability: number;
  usSSNHiSensitivityNewRecords: number;
  usSSNHiSensitivityNewLiability: number;
  creditCardAmericanExpressNewRecords: number;
  creditCardAmericanExpressNewLiability: number;
  usIndividualTaxpayerIdentificationNumberRecords: number;
  usIndividualTaxpayerIdentificationNumberLiability: number;
}

export interface ParsedReports {
  applicationsRiskReport: ApplicationsRiskReport[] | null;
  actifileRiskReport: ParsedRiskReport | null;
  actifileSensitiveFilesReport: FileDetails[] | null;
}

export interface ReportData {
  networkSecurity: {
    score: number;
    scoreText: string;
  };
  dataSecurity: {
    score: number;
    scoreText: string;
  };
  applicationSecurity: {
    score: number;
    scoreText: string;
  };
  identityAccessManagement: {
    score: number;
    scoreText: string;
  };
  securityAwarenessTraining: {
    score: number;
    scoreText: string;
  };
  m365Security: {
    score: number | null;
  };
}

export const PII_TYPE_LABELS: { [key in PiiCountry]: { [key: string]: string } } = {
  US: {
    credit_card: 'Credit Card',
    ssn: 'SSN',
    phone: 'Phone',
    // date_of_birth: 'Date of Birth',
    email: 'Email',
    ip: 'IP Address',
    oauth_token: 'oAuth Token',
    routing_number: 'Bank Routing Number',
    // swift_code: 'Bank SWIFT Code',
    iban_code: 'Bank IBAN Code',
    medical_record: 'Medical Record',
    passport: 'Passport',
  },
  CA: {
    credit_card: 'Credit Card',
    ssn: 'SIN', // different
    phone: 'Phone',
    // date_of_birth: 'Date of Birth',
    email: 'Email',
    ip: 'IP Address',
    oauth_token: 'oAuth Token',
    routing_number: 'Bank Routing Number',
    // swift_code: 'Bank SWIFT Code',
    // iban_code: 'Bank IBAN Code',
    medical_record: 'Personal Health Number', // different
    passport: 'Passport',
  },
  UK: {
    credit_card: 'Credit Card',
    // ssn: 'SIN',
    phone: 'Phone',
    // date_of_birth: 'Date of Birth',
    email: 'Email',
    ip: 'IP Address',
    oauth_token: 'oAuth Token',
    // routing_number: 'Bank Routing Number',
    // swift_code: 'Bank SWIFT Code',
    iban_code: 'Bank IBAN Code',
    medical_record: 'NHS Number', // different
    passport: 'Passport',
  },
  ZA: {
    credit_card: 'Credit Card',
    ssn: 'South Africa ID', // different
    phone: 'Phone',
    // date_of_birth: 'Date of Birth',
    email: 'Email',
    ip: 'IP Address',
    oauth_token: 'oAuth Token',
    routing_number: 'Bank Routing Number',
    // swift_code: 'Bank SWIFT Code',
    // iban_code: 'Bank IBAN Code',
    // medical_record: 'NHS Number',
    passport: 'Passport',
  },
  AU: {
    credit_card: 'Credit Card',
    ssn: 'Tax File Number', // different
    phone: 'Phone', // different
    // date_of_birth: 'Date of Birth',
    email: 'Email',
    ip: 'IP Address',
    oauth_token: 'oAuth Token',
    // routing_number: 'Bank Routing Number',
    // swift_code: 'Bank SWIFT Code',
    // iban_code: 'Bank IBAN Code',
    medical_record: 'Medicare Card Number', // different
    passport: 'Passport',
  },
  NZ: {
    credit_card: 'Credit Card',
    ssn: 'IRD Number', // different
    // phone: 'Phone',
    // date_of_birth: 'Date of Birth',
    email: 'Email',
    ip: 'IP Address',
    oauth_token: 'oAuth Token',
    // routing_number: 'Bank Routing Number',
    // swift_code: 'Bank SWIFT Code',
    // iban_code: 'Bank IBAN Code',
    medical_record: 'Health Index Number', // different
    passport: 'Passport',
  },
};

export const PII_TYPE_INFOS: { [key in PiiCountry]?: { [key: string]: string } } = {
  US: {
    credit_card: `A unique 16 digit number used for financial transactions that Telivy scans for. Credit card numbers enable unauthorized purchases and can lead to significant financial loss.`,
    ssn: `A unique identifier used in the US for social security purposes. Telivy scans for specific patterns matching this format (e.g., XXX-XX-XXXX and its derivatives). An SSN can be used for financial fraud, identity theft, and accessing government benefits in someone else's name.`,
    phone: `Telivy can find various phone number formats, including international prefixes and mobile numbers. Leaked phone numbers can be used for spam calls, phishing attempts, and targeted scams.`,
    date_of_birth: 'Date of Birth',
    email: `A common identifier for electronic mail. Telivy scans for email address formats consisting of username and domain name (e.g., vijay@telivy.com). Email addresses can be used for spam, phishing attacks, and credential stuffing (attempting to log in to other accounts using stolen email/password combinations).`,
    ip: `A unique numerical label assigned to a device on a network. By itself, an IP doesn't identify individuals, but it can reveal location (generally) and potentially be used to track online activity.`,
    oauth_token: `This acts like a key that grants access to online accounts. If stolen, someone could hijack your accounts and impersonate you.`,
    routing_number: `A unique identifier for a bank or financial institution in some countries (e.g., US). Telivy can scan for specific patterns matching these routing numbers. A routing number with account number (and potentially other info) can be used for fraudulent withdrawals.`,
    // swift_code: 'Bank SWIFT Code',
    iban_code: `An international standard for identifying bank accounts. Telivy scans for IBAN formats which include country codes, check digits, and account details. While not directly identifying, an IBAN with other information (like name) can be used for fraudulent bank transfers.`,
    medical_record: `This unique number grants access to your private medical history, which can be misused for identity theft or discrimination.`,
    passport: `The unique, identifying number that is on a passport, a document issued by a national government. It certifies the identity and nationality of its holder for the purpose of international travel.`,
  },
};

export interface PiiFileDTO {
  id: string;
  hostname: string;
  path: string;
  deepScanTargetId: string;
  piiType: keyof PiiTypes;
  matchesCount: number;
  matches: string;
  dataRisk: number;
  confidence: string;
  falsePositive: boolean;
  fileLastModified: string | null;
  fileSize: number | null;
}

export interface PiiQueryDTO {
  q?: string;
  limit?: number;
  offset?: number;
  piiType?: string;
  deepScanTargetId?: string;
  hostname?: string;
  sortBy?: string;
  sortOrder?: string;
}

export interface DetailedNodewareAsset {
  uuid: string;
  alias: string;
  license_key: string;
  report?: {
    host: {
      os: string;
      alias: string;
      ip_addr: string;
      hostname: string;
      mac_addr: string;
      mac_vendor: string;
      last_seen: string;
      first_seen: string;
      scan_stats: {
        scan_depth: number;
        start_time: string;
        end_time: string;
      };
    };
    score: number;
    totals: NodewareTotals;
    report_run: string;
    score_change: number;
    plugins: NodewareVulnerability[];
  };
  is_external: boolean;
  is_agent: boolean;
  applied_credentials: {
    credential_uuid: string;
    benchmark_id: number;
  };
  tags: Array<{
    slug: string;
    display: string;
    is_auto: boolean;
  }>;
}

export interface NodewareVulnerability {
  id: string | number;
  cve_id?: string;
  port?: number;
  urls: string[];
  title: string;
  nw_score: number;
  source: string;
  annotations?: string;
  exploitable?: boolean;
  protocol: string;
  severity: number;
  solution: string;
  processes: [];
  scan_type: string;
  description?: string | null;
  test_results: string;
  recommendation: string;
}

export interface NmapVulnerabilityData {
  id: string;
  title: string | null;
  score?: number | null;
  epss?: number | null;
  percentile?: number | null;
  epssUpdatedAt?: Date | null;
  description?: string;
  publishedDate?: string;
  foundIn: {
    product: string;
    version?: string;
    port: string;
  };
  deviceName?: string;
}

export interface TopNmapVulnerability {
  assets: number;
  assetsDetails: Array<{
    id: string;
  }>;
  data: NmapVulnerabilityData;
}

export interface SoftwaresDetailedFinding {
  id: string;
  details: Array<{
    product: string;
    version?: string;
  }>;
}

export interface ParsedNmapScan {
  softwares: {
    findings: SoftwaresDetailedFinding[];
  };
}

export interface DeepScanInstalledApplicationDTO {
  id: string;
  name: string;
  version: string;
  hostname: string;
  vendor: string | null;
  caption: string | null;
  identifyingNumber: string | null;
  source: string | null;
  provider: string | null;
  nmapScanStats: NmapScanStats | null;
  nmapScanParsed: ParsedNmapScan | null;
  deepScanId: string;
  deepScanTargetId: string | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface InstalledAppsStats {
  assets: number;
  assetsDetails: Array<{
    id: string;
  }>;
  data: DeepScanInstalledApplicationDTO;
}

export type RiskAnalysisScore = 'high' | 'medium' | 'low';
export interface RiskAnalysisItem {
  id: string;
  hostname: string;
  users: string[];
  linkedUsers: DeepScanTargetLinkedUserDTO[];
  dataRisk?: number;
  vulnerabilityScore?: RiskAnalysisScore;
  passwordSecurity?: RiskAnalysisScore;
  darkWebSecurity?: RiskAnalysisScore;
}

export type DeepTargetStatus =
  | 'install-success'
  | 'install-error'
  | 'install-not-started'
  | 'already-installed'
  | 'install-in-progress'
  | 'install-pending'
  | 'install-unknown'
  | 'install-not-supported'
  | 'install-offline'
  | 'install-partial'
  | 'install-partial-done'
  | 'uninstall-scheduled'
  | 'uninstall-in-progress'
  | 'uninstall-success'
  | 'uninstall-error'
  | 'rescan-scheduled'
  | 'rescan-schedule-error'
  | 'rescan-in-progress'
  | 'rescan-success';

export interface ExtendedDeepScanTargetDTO extends DeepScanTargetDTO {
  statuses: DeepScanTargetStatuses;
}

interface Status {
  status: DeepTargetStatus;
  statusMessage?: string;
  action?: string;
  issue?: string;
}

export interface DeepScanTargetStatuses {
  telivyAgent: Status;
  browserPassword: Status;
  vulnerabilityManagement: Status;
  application: Status;
  piiAgent: Status;
}

const isNullOrUndefined = (value: any) => {
  return value === undefined || value === null;
};

const isInProgress = (d: DeepScanTargetDTO, browserCheck?: boolean): Status | undefined => {
  // UNINSTALL SCHEDULED
  if (d.telivyAgentDetails?.uninstall_scheduled) {
    return {
      status: 'uninstall-scheduled',
      statusMessage: 'Uninstall Scheduled',
    };
  }

  // UNINSTALL IN PROGRESS
  if (d.telivyAgentDetails?.uninstall_all_log && !d.telivyAgentDetails?.uninstall_all_done) {
    return {
      status: 'uninstall-in-progress',
      statusMessage: 'Uninstall In Progress',
    };
  }

  // RESCAN SCHEDULED
  if (d.telivyAgentDetails?.rescan_scheduled) {
    if (d.lastHeartBeatAt) {
      return {
        status: 'rescan-scheduled',
        statusMessage: 'Rescan Scheduled',
      };
    } else {
      return {
        status: 'rescan-schedule-error',
        statusMessage: 'Error Scheduling Rescan',
        action: 'Please bring device online by re-running the agent deployment',
        issue: 'Device-Offline',
      };
    }
  }

  const deviceOnlineInLastHour = d.lastHeartBeatAt && moment().diff(moment(d.lastHeartBeatAt), 'minutes') <= 60;

  const scanInProgress =
    (isNullOrUndefined(d.telivyAgentDetails?.scan_applications_total_found) &&
      isNullOrUndefined(d.telivyAgentDetails?.scan_applications_error)) ||
    isNullOrUndefined(d.telivyAgentDetails?.scan_pii_total_found);

  // RESCAN IN PROGRESS
  if (d.telivyAgentDetails?.rescan_started && scanInProgress) {
    if (d.lastHeartBeatAt && !deviceOnlineInLastHour) {
      return {
        status: 'install-not-started',
        statusMessage: 'Rescan Paused (device offline)',
        action: 'Device not connected to Telivy. Ensure device is online',
        issue: 'Device-Offline',
      };
    }

    return {
      status: 'rescan-in-progress',
      statusMessage: 'Rescan In Progress',
    };
  }

  // RESCAN OR INSTALL SUCCESS
  if (
    !browserCheck &&
    d.telivyAgentDetails &&
    (d.telivyAgentDetails?.nodeware_installed || (d.telivyAgentDetails?.scan_apps_os_version && d.nmapScanStats)) &&
    !isNullOrUndefined(d.telivyAgentDetails?.scan_applications_total_found) &&
    // FIXME: return true if >=80% pii scan done
    !isNullOrUndefined(d.telivyAgentDetails?.scan_pii_total_found)
  ) {
    return {
      status: 'install-success',
      statusMessage: d.telivyAgentDetails?.rescan_started ? 'Rescan Success' : 'Admin Scan Success',
    };
  }

  // INSTALL IN PROGRESS
  if (scanInProgress && !browserCheck) {
    if (d.lastHeartBeatAt && !deviceOnlineInLastHour) {
      return {
        status: 'install-not-started',
        statusMessage: 'Admin Scan Paused (device offline)',
        action: 'Device not connected to Telivy. Ensure device is online',
        issue: 'Device-Offline',
      };
    }

    return {
      status: 'install-in-progress',
      statusMessage: 'In Progress',
    };
  }
};

const calculateTelivyAgentStatus = (d: DeepScanTargetDTO, pii: 'actifile' | 'pii' | 'telivy'): Status => {
  // UNINSTALL DONE
  if (d.telivyAgentUninstalledAt) {
    return {
      status: 'uninstall-success',
    };
  }

  // UNINSTALL SCHEDULED
  if (d.telivyAgentDetails?.uninstall_scheduled) {
    return {
      status: 'uninstall-scheduled',
    };
  }

  // NON ADMIN SCAN
  if (d.telivyAgentDetails?.run_as_admin == 'False' && !d.telivyAgentInstalledAt) {
    if (Object.values(d.browserPasswordExecutionDetails || {}).filter((b) => b.user_scanned_at).length) {
      return {
        status: 'install-partial-done',
        statusMessage: 'Non Admin Scan Success',
        // action: 'For Full Scan, Re-run the installation script as an Administrator',
        issue: 'Permissions',
      };
    }

    return {
      status: 'install-partial',
      statusMessage: 'Non Admin Scan In Progress',
      // action: 'For Full Scan, Re-run the installation script as an Administrator',
      issue: 'Permissions',
    };
  }

  // RESCAN SCHEDULED
  if (d.telivyAgentDetails?.rescan_scheduled) {
    if (d.lastHeartBeatAt) {
      return {
        status: 'rescan-scheduled',
        statusMessage: 'Rescan Scheduled',
      };
      // } else if (!d.osVersion?.includes('macOS')) {
      //   return {
      //     status: 'rescan-schedule-error',
      //     statusMessage: 'Error Scheduling Rescan',
      //     action: 'Please bring device online by re-running the installation script',
      //     issue: 'Device-Offline',
      //   };
    } else {
      return {
        status: 'rescan-schedule-error',
        statusMessage: 'Rescan Not Supported',
      };
    }
  }

  // INSTALL/RESCAN DONE
  if (d.telivyAgentInstalledAt && !d.telivyAgentDetails?.uninstall_all_started) {
    if (d.lastHeartBeatAt || d.osVersion?.includes('macOS')) {
      return {
        status: 'install-success',
        statusMessage: d.telivyAgentDetails?.rescan_started ? 'Rescan Success' : 'Admin Scan Success',
      };
    } else {
      return {
        status: 'install-offline',
        statusMessage: 'Install Offline',
        action:
          // 'Please disable AV or whitelist path “C:\\Program Files (x86)\\Telivy\\Telivy Agent” and re-run the installation script',
          'Please try uninstalling Telivy and re-running the agent deployment to install latest Telivy agent',
        // issue: 'Anti-Virus',
      };
    }
  }

  if (d.telivyAgentDetails === null) {
    return {
      status: 'install-not-started',
      statusMessage: 'Deploy Not Started',
      action: d.osVersion?.includes('macOS') ? 'Please run the system scan' : 'Please re-run the deployment',
    };
  }

  const inProgress = isInProgress(d);
  if (inProgress) {
    return inProgress;
  }

  // UNINSTALL ERROR
  if (d.telivyAgentDetails?.uninstall_all_log && !d.telivyAgentDetails?.uninstall_all_done) {
    return {
      status: 'uninstall-error',
      statusMessage: 'Uninstall Error',
      action: 'Please reach out to support',
      issue: 'Uninstall Error',
    };
  }

  const {
    nodeware_installed,
    actifile_installed,
    schedule_scan_install_done,
    augment_installed,
    pii_tools_installed,
    scan_apps_os_version,
    scan_applications_total_found,
    scan_pii_total_found,
  } = d.telivyAgentDetails || {};
  const vulFlag = nodeware_installed || (scan_apps_os_version && !isNullOrUndefined(scan_applications_total_found));
  const piiFlag = actifile_installed || pii_tools_installed || !isNullOrUndefined(scan_pii_total_found);
  const applicationsFlag =
    !isNullOrUndefined(scan_applications_total_found) || augment_installed || d.osVersion?.includes('macOS');

  if (!vulFlag || !(schedule_scan_install_done || d.browserPasswordExecutedAt) || !applicationsFlag || !piiFlag) {
    const notInstalled = [
      !vulFlag && 'Vulnerability',
      !schedule_scan_install_done && !d.browserPasswordExecutedAt && 'Passwords',
      !applicationsFlag && 'Applications',
      !piiFlag && 'PII',
    ].filter(Boolean);

    const isOnlyTelivy = notInstalled.length === 1 && notInstalled[0] === 'Passwords';
    const isNodewareError = notInstalled.indexOf('Nodeware') > -1;

    if (d.lastHeartBeatAt && !isNodewareError && d.telivyAgentDetails?.run_as_admin != 'False') {
      return {
        status: 'install-in-progress',
        // statusMessage: `Error Scanning: [${notInstalled.join(', ')}]. Retry In Progress`,
        statusMessage: `In Progress`,
      };
    }

    return {
      status: 'install-error',
      statusMessage: `Error Scanning: [${notInstalled.join(', ')}]`,
      action: isOnlyTelivy
        ? 'Please try uninstalling Telivy and re-running the agent deployment to install latest Telivy agent'
        : undefined,
      // issue: isOnlyTelivy ? 'Anti-Virus' : undefined,
    };
  }

  const nodewareResponse =
    d.telivyAgentDetails?.nodeware_installed ||
    d.telivyAgentDetails?.nodeware_error ||
    (d.telivyAgentDetails?.scan_apps_os_version &&
      !isNullOrUndefined(d.telivyAgentDetails?.scan_applications_total_found));
  const applicationsResponse =
    d.telivyAgentDetails?.augment_installed ||
    d.telivyAgentDetails?.augment_error ||
    !isNullOrUndefined(d.telivyAgentDetails?.scan_applications_total_found) ||
    d.telivyAgentDetails?.scan_applications_error ||
    d.osVersion?.includes('macOS');
  // const telivyResponse =
  //   d.browserPasswordExecutedAt ||
  //   d.telivyAgentDetails?.schedule_scan_install_done ||
  //   d.telivyAgentDetails?.telivy_error;
  const actifileResponse = d.telivyAgentDetails?.actifile_installed || d.telivyAgentDetails?.actifile_error;
  const piiToolsResponse = d.telivyAgentDetails?.pii_tools_installed || d.telivyAgentDetails?.pii_tools_error;
  const piiTelivyResponse =
    !isNullOrUndefined(d.telivyAgentDetails?.scan_pii_total_found) || d.telivyAgentDetails?.scan_pii_error;
  const piiReposne = pii === 'actifile' ? actifileResponse : pii == 'pii' ? piiToolsResponse : piiTelivyResponse;
  const hasAllResponses = nodewareResponse && piiReposne && applicationsResponse; //&& telivyResponse;

  let progressPercent = 0;
  if (d.telivyAgentDetails?.scan_pii_dryrun_files && d.telivyAgentDetails?.scan_pii_dryrun_files > 0) {
    progressPercent = ((d.piiToolsSummary?.filesScanned || 0) * 100) / d.telivyAgentDetails.scan_pii_dryrun_files;
  }

  if (d.telivyAgentDetails && !hasAllResponses) {
    if (d.lastHeartBeatAt && d.telivyAgentDetails?.run_as_admin != 'False') {
      return {
        status: 'install-in-progress',
        // statusMessage: `Error: Incomplete Execution. Retry In Progress`,
        statusMessage: `In Progress`,
      };
    }

    return {
      status: 'install-error',
      statusMessage: 'Error: Incomplete Execution',
      action:
        // 'Please disable AV or whitelist path “C:\\Program Files (x86)\\Telivy\\Telivy Agent” and re-run the installation script',
        'Please try uninstalling Telivy and re-running the agent deployment to install latest Telivy agent',
      // issue: 'Anti-Virus',
    };
  } else if (
    d.telivyAgentDetails &&
    (d.telivyAgentDetails?.nodeware_installed || (d.telivyAgentDetails?.scan_apps_os_version && d.nmapScanStats)) &&
    !isNullOrUndefined(d.telivyAgentDetails?.scan_applications_total_found) &&
    (!isNullOrUndefined(d.telivyAgentDetails?.scan_pii_total_found) || progressPercent >= 80) //&&
    // (d.telivyAgentDetails?.schedule_scan_install_done || d.browserPasswordExecutedAt)
  ) {
    return {
      status: 'install-success',
      statusMessage: d.telivyAgentDetails?.rescan_started ? 'Rescan Success' : 'Admin Scan Success',
    };
  } else if (
    d.osVersion?.includes('macOS') &&
    d.telivyAgentDetails?.nodeware_installed &&
    d.telivyAgentDetails?.actifile_installed
  ) {
    return {
      status: 'install-success',
      // statusMessage: 'Admin Scan Success',
    };
  }

  return {
    status: 'install-error',
    statusMessage: 'Error: Unknown',
    action: 'Please reach out to support',
    issue: 'Unknown Error',
  };
};

const calculateVulnerabilityManagement = (d: DeepScanTargetDTO): Status => {
  // UNINSTALL DONE
  if (
    d.telivyAgentUninstalledAt ||
    (d.telivyAgentDetails?.uninstall_all_started && d.telivyAgentDetails?.nodeware_uninstalled)
  ) {
    return {
      status: 'uninstall-success',
    };
  }

  // RESCAN IN PROGRESS
  if (
    !d.telivyAgentInstalledAt &&
    d.telivyAgentDetails?.rescan_started &&
    isNullOrUndefined(d.telivyAgentDetails?.scan_apps_os_version) &&
    isNullOrUndefined(d.telivyAgentDetails?.scan_applications_total_found)
  ) {
    return {
      status: 'rescan-in-progress',
    };
  }

  // NON ADMIN SCAN DONE
  if (d.telivyAgentDetails?.run_as_admin == 'False' && !d.telivyAgentInstalledAt) {
    if (
      Object.values(d.browserPasswordExecutionDetails || {}).filter((b) => b.scan_apps_os_version).length > 0 &&
      d.nmapScanStats
    ) {
      return {
        status: 'install-partial-done',
        statusMessage: 'Non Admin Scan Success',
      };
    }

    return {
      status: 'install-partial',
      statusMessage: 'Non Admin Scan In Progress',
    };
  }

  // INSTALL/RESCAN DONE
  if (d.telivyAgentInstalledAt && !d.telivyAgentDetails?.uninstall_all_started) {
    return {
      status: 'install-success',
      statusMessage: d.telivyAgentDetails?.rescan_started ? 'Rescan Success' : 'Admin Scan Success',
    };
  }

  if (!d.telivyAgentDetails) {
    return {
      status: 'install-not-started',
      statusMessage: 'Deploy Not Started',
      action: d.osVersion?.includes('macOS') ? 'Please run the system scan' : 'Please re-run the deployment',
    };
  }

  if (
    (d.telivyAgentDetails?.nodeware_installed ||
      (d.telivyAgentDetails?.scan_apps_os_version &&
        d.nmapScanStats &&
        !isNullOrUndefined(d.telivyAgentDetails?.scan_applications_total_found))) &&
    !d.telivyAgentDetails?.uninstall_all_started
  ) {
    return {
      status: 'install-success',
      statusMessage: d.telivyAgentDetails?.rescan_started ? 'Rescan Success' : 'Admin Scan Success',
    };
  }

  if (
    d.telivyAgentDetails?.nodeware_logs?.toLowerCase().includes('the following prerequisites') ||
    d.telivyAgentDetails?.nodeware_logs?.toLowerCase().includes('installation success or error status: 1603') ||
    d.telivyAgentDetails?.nodeware_logs?.toLowerCase().includes('failed to grab execution mutex. system error 258')
  ) {
    return {
      status: 'install-error',
      statusMessage: 'Installation Failure',
      action: 'Please reboot and then re-run the installation script',
      issue: 'Reboot Required',
    };
  }

  if (d.telivyAgentDetails?.nodeware_error?.toLowerCase().includes('already installed')) {
    return {
      status: 'already-installed',
      statusMessage: 'Already Installed',
      action: 'Uninstall existing nodeware installation and re-run the installation script',
      issue: 'Tool Conflict',
    };
  }

  if (d.telivyAgentDetails?.nodeware_error?.toLowerCase().includes('not found') && !d.lastHeartBeatAt) {
    return {
      status: 'install-error',
      statusMessage: 'Installation Failure',
      action: 'Please reach out to support',
      issue: 'Installation Failure',
    };
  }

  const inProgress = isInProgress(d);
  if (inProgress) {
    return inProgress;
  }

  if (
    !d.telivyAgentDetails.nodeware_installed &&
    !d.telivyAgentDetails?.scan_apps_os_version &&
    !d.telivyAgentDetails.nodeware_error
  ) {
    if (d.lastHeartBeatAt && d.telivyAgentDetails?.run_as_admin != 'False') {
      return {
        status: 'install-in-progress',
        // statusMessage: `Error: Incomplete Execution. Retry In Progress`,
        statusMessage: `In Progress`,
      };
    }

    if (d.telivyAgentDetails?.run_as_admin == 'False') {
      return {
        status: 'install-error',
        statusMessage: `Error: Admin Scan Needed`,
      };
    }

    return {
      status: 'install-error',
      statusMessage: 'Incomplete Execution',
      action:
        // 'Please disable AV or whitelist path “C:\\Program Files (x86)\\Telivy\\Telivy Agent” and re-run the installation script',
        'Please try uninstalling Telivy and re-running the agent deployment to install latest Telivy agent',
      // issue: 'Anti-Virus',
    };
  }

  return {
    status: 'install-unknown',
    action: 'Please reach out to support',
    issue: 'Unknown error',
  };
};

const calculatePiiAgentActifile = (d: DeepScanTargetDTO): Status => {
  // UNINSTALL DONE
  if (
    d.telivyAgentUninstalledAt ||
    (d.telivyAgentDetails?.uninstall_all_started && d.telivyAgentDetails?.actifile_uninstalled)
  ) {
    return {
      status: 'uninstall-success',
    };
  }

  // INSTALL DONE
  if (d.telivyAgentInstalledAt && !d.telivyAgentDetails?.uninstall_all_started) {
    return {
      status: 'install-success',
    };
  }

  if (!d.telivyAgentDetails) {
    return {
      status: 'install-not-started',
      statusMessage: 'Deploy Not Started',
      action: d.osVersion?.includes('macOS') ? 'Please run the system scan' : 'Please re-run the deployment',
    };
  }

  if (d.telivyAgentDetails?.actifile_installed && !d.telivyAgentDetails?.uninstall_all_started) {
    return {
      status: 'install-success',
    };
  }

  if (d.telivyAgentDetails?.actifile_error?.toLowerCase().includes('already installed')) {
    return {
      status: 'already-installed',
      statusMessage: 'Already installed',
      action: 'Uninstall existing actifile installation and re-run the installation script',
      issue: 'Tool Conflict',
    };
  }

  if (d.telivyAgentDetails?.actifile_error?.toLowerCase().includes('not found')) {
    return {
      status: 'install-error',
      statusMessage: 'Installation Failure',
      action: 'Please reach out to support',
      issue: 'Installation Failure',
    };
  }

  const inProgress = isInProgress(d);
  if (inProgress) {
    return inProgress;
  }

  if (!d.telivyAgentDetails.actifile_installed && !d.telivyAgentDetails.actifile_error) {
    if (d.lastHeartBeatAt && d.telivyAgentDetails?.run_as_admin != 'False') {
      return {
        status: 'install-in-progress',
        // statusMessage: `Error: Incomplete Execution. Retry In Progress`,
        statusMessage: `In Progress`,
      };
    }

    return {
      status: 'install-error',
      statusMessage: 'Incomplete Execution',
      action:
        // 'Please disable AV or whitelist path “C:\\Program Files (x86)\\Telivy\\Telivy Agent” and re-run the installation script',
        'Please try uninstalling Telivy and re-running the agent deployment to install latest Telivy agent',
      // issue: 'Anti-Virus',
    };
  }

  return {
    status: 'install-unknown',
    action: 'Please reach out to support',
    issue: 'Unknown error',
  };
};

const calculatePiiAgent = (d: DeepScanTargetDTO): Status => {
  // UNINSTALL DONE
  if (
    d.telivyAgentUninstalledAt ||
    (d.telivyAgentDetails?.uninstall_all_started && d.telivyAgentDetails?.pii_tools_uninstalled)
  ) {
    return {
      status: 'uninstall-success',
    };
  }

  // INSTALL DONE
  if (d.telivyAgentInstalledAt && !d.telivyAgentDetails?.uninstall_all_started) {
    return {
      status: 'install-success',
    };
  }

  if (!d.telivyAgentDetails) {
    return {
      status: 'install-not-started',
      statusMessage: 'Deploy Not Started',
      action: d.osVersion?.includes('macOS') ? 'Please run the system scan' : 'Please re-run the deployment',
    };
  }

  if (d.telivyAgentDetails?.pii_tools_installed && !d.telivyAgentDetails?.uninstall_all_started) {
    return {
      status: 'install-success',
    };
  }

  if (d.telivyAgentDetails?.pii_tools_error?.toLowerCase().includes('already installed')) {
    return {
      status: 'already-installed',
      statusMessage: 'Already installed',
      action: 'Uninstall existing pii tools installation and re-run the installation script',
      issue: 'Tool Conflict',
    };
  }

  if (d.telivyAgentDetails?.pii_tools_error?.toLowerCase().includes('not found')) {
    return {
      status: 'install-error',
      statusMessage: 'Installation Failure',
      action: 'Please reach out to support',
      issue: 'Installation Failure',
    };
  }

  const inProgress = isInProgress(d);
  if (inProgress) {
    return inProgress;
  }

  if (!d.telivyAgentDetails.pii_tools_installed && !d.telivyAgentDetails.pii_tools_error) {
    if (d.lastHeartBeatAt && d.telivyAgentDetails?.run_as_admin != 'False') {
      return {
        status: 'install-in-progress',
        // statusMessage: `Error: Incomplete Execution. Retry In Progress`,
        statusMessage: `In Progress`,
      };
    }

    return {
      status: 'install-error',
      statusMessage: 'Incomplete Execution',
      action:
        // 'Please disable AV or whitelist path “C:\\Program Files (x86)\\Telivy\\Telivy Agent” and re-run the installation script',
        'Please try uninstalling Telivy and re-running the agent deployment to install latest Telivy agent',
      // issue: 'Anti-Virus',
    };
  }

  return {
    status: 'install-unknown',
    action: 'Please reach out to support',
    issue: 'Unknown error',
  };
};

const calculatePiiTelivyScan = (d: DeepScanTargetDTO): Status => {
  // UNINSTALL DONE
  if (d.telivyAgentUninstalledAt) {
    return {
      status: 'uninstall-success',
    };
  }

  let progressPercent = '';
  if (d.telivyAgentDetails?.scan_pii_dryrun_files && d.telivyAgentDetails?.scan_pii_dryrun_files > 0) {
    const progress = ((d.piiToolsSummary?.filesScanned || 0) * 100) / d.telivyAgentDetails.scan_pii_dryrun_files;
    progressPercent = ` ${progress.toFixed(2)}% Completed`;
  }

  // RESCAN IN PROGRESS
  if (
    !d.telivyAgentInstalledAt &&
    d.telivyAgentDetails?.rescan_started &&
    isNullOrUndefined(d.telivyAgentDetails?.scan_pii_total_found)
  ) {
    return {
      status: 'rescan-in-progress',
      statusMessage: `Rescan In Progress${progressPercent}`,
    };
  }

  // NON ADMIN SCAN DONE
  if (d.telivyAgentDetails?.run_as_admin == 'False' && !d.telivyAgentInstalledAt) {
    if (
      Object.values(d.browserPasswordExecutionDetails || {}).filter((b) => !isNullOrUndefined(b.scan_pii_total_found))
        .length
    ) {
      return {
        status: 'install-partial-done',
        statusMessage: 'Non Admin Scan Success',
      };
    }

    return {
      status: 'install-partial',
      statusMessage: 'Non Admin Scan In Progress',
    };
  }

  // INSTALL/RESCAN DONE
  if (d.telivyAgentInstalledAt && !d.telivyAgentDetails?.uninstall_all_started) {
    return {
      status: 'install-success',
      statusMessage: d.telivyAgentDetails?.rescan_started ? 'Rescan Success' : 'Admin Scan Success',
    };
  }

  if (!d.telivyAgentDetails) {
    return {
      status: 'install-not-started',
      statusMessage: 'Deploy Not Started',
      action: d.osVersion?.includes('macOS') ? 'Please run the system scan' : 'Please re-run the deployment',
    };
  }

  if (!isNullOrUndefined(d.telivyAgentDetails?.scan_pii_total_found) && !d.telivyAgentDetails?.uninstall_all_started) {
    return {
      status: 'install-success',
      statusMessage: d.telivyAgentDetails?.rescan_started ? 'Rescan Success' : 'Admin Scan Success',
    };
  }

  const inProgress = isInProgress(d);
  if (inProgress) {
    if (inProgress.status == 'install-in-progress') {
      return {
        status: 'install-in-progress',
        // statusMessage: `Error: Incomplete Execution. Retry In Progress`,
        statusMessage: `In Progress${progressPercent}`,
      };
    }

    return inProgress;
  }

  if (isNullOrUndefined(d.telivyAgentDetails.scan_pii_total_found)) {
    // && !d.telivyAgentDetails.scan_pii_error) {
    if (progressPercent && d.telivyAgentDetails?.run_as_admin != 'False') {
      return {
        status: 'install-in-progress',
        // statusMessage: `Error: Incomplete Execution. Retry In Progress`,
        statusMessage: `In Progress${progressPercent}`,
      };
    }

    return {
      status: 'install-error',
      statusMessage: 'Incomplete Execution',
      action:
        // 'Please disable AV or whitelist path “C:\\Program Files (x86)\\Telivy\\Telivy Agent” and re-run the installation script',
        'Please try uninstalling Telivy and re-running the agent deployment to install latest Telivy agent',
      // issue: 'Anti-Virus',
    };
  }

  if (d.lastHeartBeatAt && d.telivyAgentDetails?.run_as_admin != 'False') {
    return {
      status: 'install-in-progress',
      // statusMessage: `Scan Error. Retry In Progress`,
      statusMessage: `In Progress${progressPercent}`,
    };
  }

  return {
    status: 'install-unknown',
    action: 'Please reach out to support',
    issue: 'Unknown error',
  };
};

const calculateApplicationsRisk = (d: DeepScanTargetDTO): Status => {
  // UNINSTALL DONE
  if (
    d.telivyAgentUninstalledAt ||
    (d.telivyAgentDetails?.uninstall_all_started && d.telivyAgentDetails?.augment_uninstalled)
  ) {
    return {
      status: 'uninstall-success',
    };
  }

  // RESCAN IN PROGRESS
  if (
    !d.telivyAgentInstalledAt &&
    d.telivyAgentDetails?.rescan_started &&
    isNullOrUndefined(d.telivyAgentDetails?.scan_applications_total_found)
  ) {
    return {
      status: 'rescan-in-progress',
    };
  }

  // NON ADMIN SCAN DONE
  if (d.telivyAgentDetails?.run_as_admin == 'False' && !d.telivyAgentInstalledAt) {
    if (
      Object.values(d.browserPasswordExecutionDetails || {}).filter(
        (b) => !isNullOrUndefined(b.scan_applications_total_found),
      ).length
    ) {
      return {
        status: 'install-partial-done',
        statusMessage: 'Non Admin Scan Success',
      };
    }

    return {
      status: 'install-partial',
      statusMessage: 'Non Admin Scan In Progress',
    };
  }

  // INSTALL/RESCAN DONE
  if (d.telivyAgentInstalledAt && !d.telivyAgentDetails?.uninstall_all_started) {
    return {
      status: 'install-success',
      statusMessage: d.telivyAgentDetails?.rescan_started ? 'Rescan Success' : 'Admin Scan Success',
    };
  }

  if (!d.telivyAgentDetails) {
    return {
      status: 'install-not-started',
      statusMessage: 'Deploy Not Started',
      action: d.osVersion?.includes('macOS') ? 'Please run the system scan' : 'Please re-run the deployment',
    };
  }

  if (
    (d.telivyAgentDetails?.augment_installed ||
      !isNullOrUndefined(d.telivyAgentDetails?.scan_applications_total_found)) &&
    !d.telivyAgentDetails?.uninstall_all_started
  ) {
    return {
      status: 'install-success',
      statusMessage: d.telivyAgentDetails?.rescan_started ? 'Rescan Success' : 'Admin Scan Success',
    };
  }

  if (d.telivyAgentDetails?.scan_applications_error && !d.lastHeartBeatAt) {
    return {
      status: 'install-error',
      statusMessage: 'Execution Failure',
      action: 'Please reach out to support',
      issue: 'Execution Failure',
    };
  }

  if (d.telivyAgentDetails?.augment_error?.toLowerCase()?.includes('already installed')) {
    return {
      status: 'install-error',
      statusMessage: 'Already installed',
      action: 'Uninstall existing augment installation and re-run the installation script',
      issue: 'Tool Conflict',
    };
  }

  if (d.telivyAgentDetails?.augment_error?.toLowerCase().includes('not found') && !d.lastHeartBeatAt) {
    return {
      status: 'install-error',
      statusMessage: 'Installation Failure',
      action: 'Please reach out to support',
      issue: 'Installation Failure',
    };
  }

  const inProgress = isInProgress(d);
  if (inProgress) {
    return inProgress;
  }

  if (
    isNullOrUndefined(d.telivyAgentDetails?.scan_applications_total_found) &&
    !d.telivyAgentDetails?.scan_applications_error &&
    !d.telivyAgentDetails.augment_installed &&
    !d.telivyAgentDetails.augment_error
  ) {
    if (d.lastHeartBeatAt && d.telivyAgentDetails?.run_as_admin != 'False') {
      return {
        status: 'install-in-progress',
        // statusMessage: `Error: Incomplete Execution. Retry In Progress`,
        statusMessage: `In Progress`,
      };
    }

    return {
      status: 'install-error',
      statusMessage: 'Incomplete Execution',
      action:
        // 'Please disable AV or whitelist path “C:\\Program Files (x86)\\Telivy\\Telivy Agent” and re-run the installation script',
        'Please try uninstalling Telivy and re-running the agent deployment to install latest Telivy agent',
      // issue: 'Anti-Virus',
    };
  }

  if (d.lastHeartBeatAt && d.telivyAgentDetails?.run_as_admin != 'False') {
    return {
      status: 'install-in-progress',
      // statusMessage: `Scan Error. Retry In Progress`,
      statusMessage: `In Progress`,
    };
  }

  return {
    status: 'install-unknown',
    action: 'Please reach out to support',
    issue: 'Unknown error',
  };
};

const calculateBrowsersPassword = (d: DeepScanTargetDTO): Status => {
  // UNINSTALL DONE
  if (
    d.telivyAgentUninstalledAt ||
    (d.telivyAgentDetails?.uninstall_all_started && d.telivyAgentDetails?.schedule_scan_uninstalled)
  ) {
    return {
      status: 'uninstall-success',
    };
  }

  // UNINSTALL SCHEDULED
  if (d.telivyAgentDetails?.uninstall_scheduled) {
    return {
      status: 'uninstall-scheduled',
    };
  }

  // RESCAN DONE
  if (d.browserPasswordExecutedAt && d.telivyAgentDetails?.rescan_started) {
    return {
      status: 'rescan-success',
    };
  }

  // RESCAN IN PROGRESS
  if (!d.telivyAgentInstalledAt && d.telivyAgentDetails?.rescan_started && d.browserPasswordExecutedAt === null) {
    return {
      status: 'rescan-in-progress',
    };
  }

  // RESCAN SCHEDULED
  if (d.telivyAgentDetails?.rescan_scheduled) {
    if (d.lastHeartBeatAt) {
      return {
        status: 'rescan-scheduled',
        statusMessage: 'Rescan Scheduled',
      };
    } else {
      return {
        status: 'rescan-schedule-error',
        statusMessage: 'Error Scheduling Rescan',
        action: 'Please bring device online by re-running the agent deployment',
        issue: 'Device-Offline',
      };
    }
  }

  // NON ADMIN SCAN DONE
  if (
    d.telivyAgentDetails?.run_as_admin == 'False' &&
    !d.telivyAgentInstalledAt &&
    Object.values(d.browserPasswordExecutionDetails || {}).filter((b) => !isNullOrUndefined(b.total_passwords_found))
      .length
  ) {
    return {
      status: 'install-partial-done',
      statusMessage: 'Non Admin Scan Success',
    };
  }

  if (d.browserPasswordExecutedAt !== null && !d.telivyAgentDetails?.uninstall_all_started) {
    return {
      status: 'install-success',
      statusMessage: d.telivyAgentDetails?.rescan_started ? 'Rescan Success' : 'Admin Scan Success',
    };
  }

  if (!d.telivyAgentDetails) {
    return {
      status: 'install-not-started',
      statusMessage: 'Deploy Not Started',
    };
  }

  if (
    !d.osVersion?.includes('macOS') &&
    d.telivyAgentDetails?.schedule_scan_install_done !== null &&
    d.browserPasswordExecutedAt === null &&
    !d.telivyAgentDetails?.uninstall_all_started &&
    d.telivyAgentDetails?.telivy_error == null
  ) {
    return {
      status: 'install-pending',
      statusMessage: 'Scheduled (waiting for users to login)',
      // action: 'Please wait for user to login for password scan to execute',
    };
  }

  if (d.telivyAgentDetails?.telivy_error?.toLowerCase().includes('already installed')) {
    return {
      status: 'install-error',
      statusMessage: 'Already installed',
      // action: 'Run the uninstallation script and re-run the installation script',
      issue: 'Tool Conflict',
    };
  }

  if (d.telivyAgentDetails?.telivy_error?.toLowerCase().includes('not found')) {
    if (d.lastHeartBeatAt && d.telivyAgentDetails?.run_as_admin != 'False') {
      return {
        status: 'install-in-progress',
        // statusMessage: `Installation Error. Retry In Progress`,
        statusMessage: `In Progress`,
      };
    }

    return {
      status: 'install-error',
      statusMessage: 'Installation Error',
      action:
        // 'Please disable AV or whitelist path “C:\\Program Files (x86)\\Telivy\\Telivy Agent” and re-run the installation script',
        'Please try uninstalling Telivy and re-running the agent deployment to install latest Telivy agent',
      // issue: 'Anti-Virus',
    };
  }

  if (d.telivyAgentDetails?.telivy_error === 'no_logs_found') {
    return {
      status: 'install-pending',
      statusMessage: 'Scheduled (retrying on logged in users)',
      // action: 'Please wait for all users to login for password scan to execute',
      // issue: 'Anti-Virus',
    };
  }

  if (d.telivyAgentDetails?.telivy_error?.toLowerCase().includes('permission denied')) {
    return {
      status: 'install-error',
      statusMessage: 'Installation Error',
      action:
        // 'Please disable AV or whitelist path “C:\\Program Files (x86)\\Telivy\\Telivy Agent” and re-run the installation script',
        'Please try uninstalling Telivy and re-running the agent deployment to install latest Telivy agent',
      // issue: 'Anti-Virus',
    };
  }

  if (d.telivyAgentDetails?.telivy_error?.toLowerCase().includes('errno 22')) {
    return {
      status: 'install-error',
      statusMessage: 'Installation Error',
      action:
        // 'Please disable AV or whitelist path “C:\\Program Files (x86)\\Telivy\\Telivy Agent” and re-run the installation script',
        'Please try uninstalling Telivy and re-running the agent deployment to install latest Telivy agent',
      // issue: 'Anti-Virus',
    };
  }

  const inProgress = isInProgress(d, true);
  if (inProgress) {
    return inProgress;
  }

  if (d.telivyAgentDetails?.schedule_scan_install_done === null || !d.browserPasswordExecutionDetails) {
    return {
      status: 'install-not-started',
      statusMessage: 'Deploy Not Started',
      action: d.osVersion?.includes('macOS') ? 'Please run the user scan' : 'Please re-run the deployment',
    };
  }

  return {
    status: 'install-unknown',
    action: 'Please reach out to support',
    issue: 'Unknown error',
  };
};

export const computeDeepScanTargetStatuses = (
  d: DeepScanTargetDTO,
  pii: 'actifile' | 'pii' | 'telivy',
): DeepScanTargetStatuses => {
  let piiStatus;
  if (pii == 'actifile') {
    piiStatus = calculatePiiAgentActifile(d);
  } else if (pii == 'pii') {
    piiStatus = calculatePiiAgent(d);
  } else {
    piiStatus = calculatePiiTelivyScan(d);
  }

  return {
    telivyAgent: calculateTelivyAgentStatus(d, pii),
    vulnerabilityManagement: calculateVulnerabilityManagement(d),
    piiAgent: piiStatus,
    application: calculateApplicationsRisk(d),
    browserPassword: calculateBrowsersPassword(d),
  };
};

export const combineDeepScanActions = (statuses: DeepScanTargetStatuses): string[] => {
  const actions: string[] = Object.values(statuses)
    .map((s) => s.action)
    .filter(Boolean);

  return uniq(actions);
};

export const combineDeepScanIssues = (statuses: DeepScanTargetStatuses): string[] => {
  const issues: string[] = Object.values(statuses)
    .map((s) => s.issue)
    .filter(Boolean);

  return uniq(issues);
};

export const computeUserApplicationsStatus = (d?: BrowserPasswordDetailsDTO | null): Status | null => {
  if (isNullOrUndefined(d?.scan_applications_log)) {
    return null;
  }

  if (d?.scan_applications_error) {
    return {
      status: 'install-error',
      statusMessage: 'Applications Execution Error',
    };
  }

  if (isNullOrUndefined(d?.scan_applications_total_found)) {
    return {
      status: 'install-in-progress',
      statusMessage: 'Applications In Progress',
    };
  }

  if (!isNullOrUndefined(d?.scan_applications_total_found)) {
    return {
      status: 'install-success',
      statusMessage: `${d?.scan_applications_total_found} applications found`,
    };
  }

  return {
    status: 'install-error',
    statusMessage: 'Applications Unknown error',
  };
};

export const computeUserPiiStatus = (d?: BrowserPasswordDetailsDTO | null): Status | null => {
  if (isNullOrUndefined(d?.scan_pii_log)) {
    return null;
  }

  if (d?.scan_pii_error) {
    return {
      status: 'install-error',
      statusMessage: 'PII Execution Error',
    };
  }

  if (isNullOrUndefined(d?.scan_pii_total_found)) {
    return {
      status: 'install-in-progress',
      statusMessage: 'PII In Progress',
    };
  }

  if (!isNullOrUndefined(d?.scan_pii_total_found)) {
    return {
      status: 'install-success',
      statusMessage: `${d?.scan_pii_total_found} files with PII found`,
    };
  }

  return {
    status: 'install-error',
    statusMessage: 'PII Unknown error',
  };
};

export const computeUserBrowserPasswordStatus = (
  t: DeepScanTargetDTO,
  d?: BrowserPasswordDetailsDTO | null,
): Status => {
  if (isNullOrUndefined(d)) {
    return {
      status: 'install-not-started',
      statusMessage: 'Deploy Not Started',
    };
  }

  if (d?.installed_browsers && d?.installed_browsers.length === 0) {
    return {
      status: 'install-not-supported',
      statusMessage: 'No supported browsers detected',
    };
  }

  if (
    isNullOrUndefined(d?.total_passwords_found) ||
    (isNullOrUndefined(t.telivyAgentInstalledAt) && isNullOrUndefined(t.browserPasswordExecutedAt))
  ) {
    return {
      status: 'install-pending',
      statusMessage: 'Passwords Scheduled',
    };
  }

  if (!isNullOrUndefined(d?.total_passwords_found)) {
    return {
      status: 'install-success',
      statusMessage: `${d?.total_passwords_found} passwords found`,
    };
  }

  return {
    status: 'install-error',
    statusMessage: 'Passwords Unknown error',
  };
};

// export const encryptData = (password: string, value: string) => {
//   const data = `${' '.repeat(16)}${value}`;
//   // const pad = 16 - (data.length % 16);
//   // data = `${data}${' '.repeat(pad)}`;
//   // console.log(`encryptData ${value} ${password} data=${data}`);

//   // Create key from key
//   let m = crypto.createHash('md5').update(password);
//   const key = m.digest('hex');
//   // console.log(`encryptData ${value} ${password} key=${key}`);

//   m = crypto.createHash('md5').update(password + password);
//   const iv = m.digest('hex').slice(0, 16);
//   // console.log(`encryptData ${value} ${password} iv=${iv}`);

//   try {
//     const cipher = crypto.createCipheriv('aes-256-cbc', key, iv);

//     const encrypted = cipher.update(data, 'utf-8', 'base64') + cipher.final('base64');
//     // console.log(`encryptData ${value} ${password} encrypted=${encrypted}`);

//     // Convert normal base64 to urlsafe base64
//     return encrypted.replace(/\+/g, '-').replace(/\//g, '_');
//   } catch (err) {
//     console.error('ENCRYPT ERROR:', err);
//     return null;
//   }
// };

// export const decryptData = (password: string, value: string) => {
//   // Convert urlsafe base64 to normal base64
//   value = value.replace(/-/g, '+').replace(/_/g, '/');
//   // console.log(`decryptData ${value} ${key} value=${value}`);

//   // Create key from key
//   let m = crypto.createHash('md5').update(password);
//   const key = m.digest('hex');
//   // console.log(`decryptData ${value} ${password} key=${key}`);

//   m = crypto.createHash('md5').update(password + password);
//   const iv = m.digest('hex').slice(0, 16);
//   // console.log(`decryptData ${value} ${password} iv=${iv}`);

//   try {
//     const decipher = crypto.createDecipheriv('aes-256-cbc', key, iv);

//     const decrypted = decipher.update(value, 'base64') + decipher.final('base64');
//     // console.log(`decryptData ${value} ${password} decrypted=${decrypted}`);

//     const textval = Buffer.from(decrypted, 'base64').toString('utf8');
//     // console.log(`decryptData ${value} ${password} textval=${textval}`);

//     return textval;
//   } catch (err) {
//     console.error('DECRYPT ERROR:', err);
//     return null;
//   }
// };

export const getDeepScanPassPhrase = (deepScanId: string): string | null => {
  // const passPhraseStored = localStorage.getItem(`DEEPSCAN-PP-CONFIG-${deepScanId}`);
  // return passPhraseStored ? decryptData(deepScanId, passPhraseStored) : null;
  return localStorage.getItem(`DEEPSCAN-PP-CONFIG-${deepScanId}`);
};

export const setDeepScanPassPhrase = (deepScanId: string, passPhrase: string | null) => {
  if (passPhrase) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return localStorage.setItem(`DEEPSCAN-PP-CONFIG-${deepScanId}`, encryptData(deepScanId, passPhrase));
  } else {
    return localStorage.removeItem(`DEEPSCAN-PP-CONFIG-${deepScanId}`);
  }
};
